export function calculateReadTime(words, images) {
	const wordsPerMinute = 265
	const secondsPerImage = 9

	let time = words / wordsPerMinute

	time = time + (images * secondsPerImage) / 60
	return `${Math.ceil(time)}min read`
}

export function processQueryStrings(queryObject) {
	if (Object.keys(queryObject).length > 0) {
		let query = ''
		let index = 0
		for (const queryKey in queryObject) {
			query = `${query}${queryKey}=${queryObject[queryKey]}`
			query = index < Object.keys(queryObject).length - 1 ? `${query}&` : query
			index++
		}
		return query
	}
	return null
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function stripHtml(content) {
	const html = new DOMParser().parseFromString(content, 'text/html')
	return html.body.textContent || ''
}

export function compare(objectA, objectB, key) {
	return objectA[key] > objectB[key] ? 1 : objectB[key] > objectA[key] ? -1 : 0
}

export function getURLParamString(fullPath) {
	return fullPath.split('?')[1]
}

// Theory Resource functions
export function hasFilter(resources) {
	return (
		resources.findIndex(
			(resource) =>
				window.location.hash ===
				`#${resource.content.title.replace(/\s+/g, '-').toLowerCase()}`
		) !== -1
	)
}

export function resourceFiltered(item) {
	const filtered =
		window.location.hash ===
		`#${item.content.title.replace(/\s+/g, '-').toLowerCase()}`

	return filtered
}

export function checkIfType(type, name) {
	if (type) {
		return type.some((typeObject) => {
			if (typeObject.name === name) return true
		})
	}
	return false
}
